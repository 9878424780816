.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Start-button {
  height: 30px;
  width: 100px
}

.timer {
  display: flex;
  font-size: 20px;
  justify-content: center;

}
.timer > div {
  padding: 10px;
  color: #fff;
  font-size: 100px;
  margin-right: 2px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.timer > div > span {
  color: #E2E4E7;
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.expired {
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(246, 71, 71, .85);
  font-size: 100px;
  color: white;
  padding: 20px;
  height: 250px;
  width: 100%;
}

.template {
  margin-left: 20px;
	-webkit-transition: margin 0.4s ease-out;
    -moz-transition: margin 0.4s ease-out;
    -o-transition: margin 0.4s ease-out;
}

.template:hover {
  margin-top: -10px;
  border: white solid 2px;
}
.templates-container {
  justify-content: center;
  display: inline-flex;
  flex-direction: row;
}

.timer-Wedding {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(./assets/images/wedding.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

.timer-Birthday {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(./assets/images/birthday.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

.timer-Graduation {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(./assets/images/graduation.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

.timer-Apocalypse {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(./assets/images/apocalypse.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

.timer-Halloween {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(./assets/images/halloween.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

.layer {
  background-color: rgba(57, 56, 56, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 25%;
  display: inline-flex;
  justify-content: center;
}

.timer-container {
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.timer-header-Apocalypse {
  background-color: #484848;
  height: 50px;
  width: 1000px;
  font-size: 40px;
  color: #EC131D;
  font-weight: bold;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: 'Cinzel', serif;
}

.timer-header-Wedding {
  background-color: black;
  height: 50px;
  width: 600px;
  font-size: 40px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: 'Dancing Script', cursive;
}

.timer-header-Birthday {
  background-color: #FFBA37;
  height: 75px;
  width: 600px;
  color: #5300C6;
  border: 5px solid #5300C6;
  font-size: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: 'Henny Penny'
}

.timer-header-Graduation {
  background-color: black;
  height: 75px;
  width: 600px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: 'Pacifico', cursive;
}

.timer-header-Halloween {
  background-color: black;
  height: 75px;
  width: 600px;
  color: orange;
  font-size: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: 'Syne Tactile', cursive;
}

img {
  height: 175px;
  width: 300px
}

a {
  color: #FFF;
  background-color: black;
}

.seconds {
  color: red !important;
}
